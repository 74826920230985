import React from "react";

// rafce
const Header = () => {
  return (
    <>
      <h1>Geburtstags Hangman</h1>
      <p>Gib einen Buchstaben ein</p>
    </>
  );
};

export default Header;
